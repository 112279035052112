.OverviewV2 {
  .tab-main-content {
    display: grid;
    grid-template-columns: 1fr 424px;
    gap: 24px;

    .main-content.left-content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 24px;
    }

    @media screen and (max-width: 1023px) {
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: 767px) {
      gap: 16px;

      .main-content.left-content {
        gap: 16px;
      }
    }
  }

  .left-box {
    display: flex;
    padding: var(--Base-Unit-L-24, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    background: linear-gradient(180deg, #191b2e 0%, #191b2e 100%);
    position: relative;

    > svg {
      position: absolute;
      width: 36px;
      height: 36px;
      left: 0;
      top: 0;
      border-radius: 16px 0 0 0;
    }

    .left-box-heading {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      .left-box-token {
        display: flex;
        align-items: center;
        gap: var(--Base-Unit-S-12, 12px);

        img {
          width: 40px;
          height: 40px;
        }

        .left-box-token-info {
          display: flex;
          height: 46px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: var(--Border_radius, 4px);

          .left-box-token-info-symbol {
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
          }

          .left-box-token-info-price {
            color: var(--White-60, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */

            &:has(.skeleton-box) {
              display: flex;
              align-items: center;
            }

            span {
              color: var(--White-100, #fff);
              font-size: 14px;
              font-weight: 700;
              line-height: 140%; /* 19.6px */
              margin-left: 4px;
            }
          }
        }
      }

      .view-btn {
        color: var(--White-100, #fff);
        font-size: 14px;
        font-weight: 700 !important;
        line-height: 140%; /* 19.6px */
        padding-left: 16px;
        padding-right: 16px;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
    .left-box-body {
      display: flex;
      align-items: flex-start;
      gap: var(--Base-Unit-L-24, 24px);
      align-self: stretch;

      .left-box-body-left {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        width: 200px;

        .left-box-body-left-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Border_radius, 4px);
          flex: 1 0 0;

          .left-box-body-left-box-title {
            color: var(--White-60, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
          }

          .left-box-body-left-box-value {
            color: var(--Main-Primary, #03f5ae);
            font-size: 16px;
            font-weight: 700;
            line-height: 140%; /* 22.4px */

            &:has(.skeleton-box) {
              display: flex;
            }
          }
        }
      }
      .left-box-body-right {
        display: flex;
        padding-left: var(--Base-Unit-L-24, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Base-Unit-S-12, 12px);
        flex: 1 0 0;
        align-self: stretch;
        border-left: 1px solid var(--White-10, rgba(255, 255, 255, 0.1));

        .left-box-body-right-row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;

          .left-box-body-right-row-title {
            color: var(--White-60, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */

            &:has(.dot) {
              display: flex;
              align-items: center;
              gap: 4px;
            }
          }
          .left-box-body-right-row-value {
            color: var(--White-100, #fff);
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
            text-align: right;

            &:has(.left-box-body-right-row-value-sub) {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 4px;
            }

            &:has(.skeleton-box) {
              display: flex;
            }

            .value {
              text-align: right;

              &:has(.skeleton-box) {
                display: flex;
              }
            }

            span {
              color: var(--White-60, rgba(255, 255, 255, 0.6));
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
              margin-left: 4px;
            }
          }
        }
      }
    }
    .view-btn-mobile {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    @media screen and (max-width: 1023px) {
      .left-box-body {
        flex-direction: column;
        gap: 12px;

        .left-box-body-left {
          width: unset;
          flex-direction: column;
          align-items: stretch;
          gap: 12px;

          .left-box-body-left-box {
            flex-direction: row;
            justify-content: space-between;

            .left-box-body-left-box-value {
              font-size: 14px;
            }
          }
        }

        .left-box-body-right {
          border: none;
          padding-left: 0;
        }
      }
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }
  }

  .main-content.right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px !important;
    background: linear-gradient(219deg, rgba(0, 46, 88, 0.8) 22.37%, rgba(25, 27, 46, 0.8) 77.63%) !important;

    .right-box {
      display: flex;
      padding: var(--Base-Unit-L-24, 24px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Base-Unit-L-24, 24px);
      align-self: stretch;

      &.right-box-bottom {
        border-top: 1px solid var(--White-10, rgba(255, 255, 255, 0.1));
      }

      .right-box-heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        .right-box-heading-title {
          color: var(--White-100, #fff);
          font-size: 16px;
          font-weight: 700;
          line-height: 140%; /* 22.4px */
        }

        .right-box-heading-reward {
          color: var(--White-60, rgba(255, 255, 255, 0.6));
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          display: flex;
          height: 58px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          gap: var(--Border_radius, 4px);

          div {
            color: var(--Main-Primary, #03f5ae);
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 33.6px */
          }
        }
      }

      .right-box-rows {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Base-Unit-S-12, 12px);
        align-self: stretch;

        .right-box-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          .right-box-row-title {
            color: var(--White-60, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
          }

          .right-box-row-value {
            color: var(--White-100, #fff);
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 19.6px */

            .Tooltip.fit-content.custom {
              .Tooltip-popup {
                width: fit-content;
                word-break: keep-all;
                min-width: unset;
                white-space: nowrap;
              }
            }

            .span-html {
              color: var(--White-100, #fff);
              font-size: 14px;
              font-weight: 700;
              line-height: 140%; /* 19.6px */
            }

            span {
              color: var(--White-60, rgba(255, 255, 255, 0.6));
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
              margin-left: 4px;
            }
          }
        }
      }

      .actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--Base-Unit-S-12, 12px);
        margin-top: 0;

        &.alone {
          grid-template-columns: 1fr;
        }

        button {
          font-weight: 700 !important;
        }
      }

      @media screen and (max-width: 767px) {
        padding: 16px;
        gap: 16px;
      }
    }
  }
}
