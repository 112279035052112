.Collateral-Modal-Withdraw {
  padding: 12px 16px !important;

  .Modal-content {
    max-width: 424px !important;
    width: 100% !important;

    .Modal-title-bar {
      padding: 12px 16px !important;
      margin: 0;
    }
  }
  .Modal-description {
    font-size: 14px;
  }
  .Modal-description {
    font-size: 14px;
  }
  .Exchange-swap-max {
    padding: 2px 12px !important;
  }
  .Exchange-swap-section {
    margin: 16px 0px !important;
  }
  .TokenSelector {
    .wrapper-symbol {
      display: flex;
      align-items: center;
      gap: 2px;
    }
    span {
      padding-top: 2px;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }
  }
}
