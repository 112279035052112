.theme--light {
  .account-overview-wrapper {
    background: #f2f5f7 !important;

    .desc-overview {
      color: var(--text-text-secondary, rgba(0, 0, 0, 0.6));
    }

    .account-address {
      svg {
        path {
          fill: #0D1A16;
        }
        &:hover {
          path {
            fill: #02B27F !important;
          }
        }
      }
    }
    .account-overview {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
      background: radial-gradient(285.88% 111.8% at 100% 0%, #F2FFFB 0%, #DAF2EB 100%) !important;
    }

    .border-btn-2 {
      border: 1px solid var(--Border_Highlight, rgba(36, 37, 38, 0.1)) !important;
      color: #000 !important;
    }

    .stats-boxes-wrapper {

      .box-item-wrapper {
        background: white !important;

        .stats-title {
          color: var(--text-text-secondary, rgba(0, 0, 0, 0.6));
        }
      }
    }

    .account-overview-tabs {
      // background: white !important;
      color: black !important;
      // border: 1px solid var(--Border_Highlight, rgba(36, 37, 38, 0.1));
    }

    .Tab-option.active {
      // background: var(--Border_Highlight, rgba(36, 37, 38, 0.1));
      background: var(--HighLight, rgba(2, 178, 127, 0.15));
    }

    .table-content {
      &:hover {
        background: var(--Border_Highlight, rgba(36, 37, 38, 0.1)) !important;
      }
    }
  }

  .Exchange-list-header {
    th {
      span {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .App-card-divider {
    background-color: rgba(36, 37, 38, 0.1);
  }

  .Exchange-list-title {
    color: #000;
  }

  .balance-column {
    .Tooltip-handle {
      color: #000;
    }
  }

  .account-action {

    // button.border-btn-2:disabled {
    //   color: #666 !important;
    // }
    button.border-btn-2:hover {
      border: 1px solid #296eff !important;
      color: #296eff !important;
    }
  }
  .account-stt {
    background: transparent;
    .dot-conected {
      background: #02B27F !important;
    }
    div {
      &:last-child {
        color: #02B27F;
      }
    }
  }
}

.account-overview-wrapper {
  background: url('../img/earn/bg.svg');
  background-repeat: no-repeat;
}

.warning-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--base-unit-s-12, 12px);
  border: 1px solid #FFA722;
  background: rgba(255, 167, 34, 0.10);
  padding: 24px;

  .warning-content {
    display: flex;
    align-items: center;
    gap: 16px;

    .warning-info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%
      }

      .desc {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: var(--white-60, rgba(255, 255, 255, 0.60));
      }

      @media screen and (max-width: 1350px) {

        .title {
          font-size: 18px;
        }
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
        gap: 8px;

        .title {
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 1350px) {
    gap: 16px;

    button {
      width: 180px;
    }
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    button {
      width: 100%;
    }
  }
}
