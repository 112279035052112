.TradeHistory-row {
  font-size: var(--font-sm);
  padding: 1.5rem;
  margin-bottom: 0.8rem;
}

.TradeHistory-time {
  font-size: 1.25rem;
  margin-bottom: 0.155rem;
}

.TradeHistory.with-scroll {
  overflow-y: auto;
}

.TradeHistory:has(img[alt="share"]) a:hover img {
  filter: invert(44%) sepia(98%) saturate(578%) hue-rotate(118deg) brightness(97%) contrast(93%);
}

@media screen and (min-width: 1110px) {
  .TradeHistory.with-scroll {
    height: 300px;
  }
}
