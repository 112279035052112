.Bootstrap-Modal {
  .Modal-title-bar {
    margin: 12px 24px;
  }
  .Modal-body {
    padding: 24px !important;
  }
  .Modal-content {
    width: 100%;
    max-width: 400px;
  }
  .box {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: var(--Border_radius, 4px);
    align-self: stretch;
    border-radius: 16px;
    background: var(--v3-Natural_1, #191b2e);

    .box-title {
      color: var(--White-60, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }

    .box-value {
      color: var(--White-100, #fff);
      font-size: 24px;
      font-weight: 700;
      line-height: 140%; /* 33.6px */

      &.green {
        color: var(--Main-Primary, #03f5ae);
      }
    }

    .box-inUsd {
      color: var(--White-60, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
  }
  .text {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-top: 12px;
  }
  .warning {
    color: var(--functional-orange, #ffa722);
    font-size: 14px;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin: 16px 0 8px;
  }

  .lock {
    display: flex;
    align-items: center;
    gap: 4px;

    color: var(--text-text-secondary, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 120%; /* 16.8px */

    span {
      font-weight: 700;
    }
  }
}
