.Modal {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.Modal-scrollable .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 5.5rem);
  overflow-y: auto;
  padding-right: 5px;
}

.Modal-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.Modal-content {
  z-index: 20;
  position: relative;
  max-width: 100%;
  max-height: 90vh;
  min-width: 300px;
  overflow: auto;
  background: #20233b;
  border-radius: 16px;
}
@media screen and (max-width: 700px) {
  .Modal-content {
    width: calc(100vw - 32px);
  }
}
.divider {
  border-bottom: 1px solid #ffffff29;
  margin-bottom: 1.5rem;
}

.Modal.non-scrollable .Modal-content {
  overflow: visible;
}

.Modal-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Modal-body {
  /* margin: 24px; */
}
@media screen and (max-width: 767px) {
  .Modal-body {
    padding: 0 16px 16px 16px;
    margin: 0;
  }
}
.Modal-body::-webkit-scrollbar {
  width: 0.6rem;
}

.Modal-body::-webkit-scrollbar-track {
  border-radius: 155rem;
}

.Modal-body::-webkit-scrollbar-thumb {
  border-radius: 155rem;
}

.Modal-title {
  text-align: left;
  font-size: 20px;
  line-height: 1;
}

.Modal-close-button {
  text-align: right;
}

.Modal-close-icon {
  opacity: 0.6;
  cursor: pointer;
  text-align: right;
  display: inline-block;
}

.Modal-close-icon:hover {
  opacity: 0.9;
}

.Modal-note {
  margin-bottom: 1.5rem;
  margin-top: 0.8rem;
}

.Modal-bottom {
  width: 100%;
  margin-top: auto;
}

.Modal .content-box-rows {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  align-self: stretch;
  margin: 24px 0 0;
}

.Modal .content-box-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.Modal .content-box-row-label {
  color: var(--text-text-secondary, rgba(255, 255, 255, 0.6));
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.Modal .content-box-row-value {
  color: var(--text-text-primary, #fff);
  font-size: 14px;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
}

.Modal.theme--light .Modal-content {
  background: #fff !important;
}

.Modal.theme--light * {
  color: #0d1a16 !important;
}

.Modal.theme--light .Modal-content > .divider {
  border-color: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
}

.Modal.theme--light .Modal-content .Modal-body {
  border-top: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
}

.Modal.theme--light .Modal-content:has(.box) {
  background: var(--Nature-2, #f2f5f7) !important;
}

.Modal.theme--light .Modal-content .Exchange-swap-section,
.Modal.theme--light .Modal-content .Exchange-swap-section--v2 {
  background: var(--Background-Input_Background, rgba(38, 77, 65, 0.07)) !important;
}

.Modal.theme--light .Modal-content .Exchange-swap-section input::placeholder,
.Modal.theme--light .Modal-content .Exchange-swap-section--v2 input::placeholder {
  color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
}

.Modal.theme--light.Connect-wallet-modal-v2 .Modal-content {
  background-color: #fff !important;
}
.Modal.theme--light.Connect-wallet-modal-v2 .Modal-content .Modal-body .wallet-desc {
  color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
}
.Modal.theme--light.Connect-wallet-modal-v2 .Modal-content .Modal-body .learn-to-connect {
  color: #02b27f !important;
}
.Modal.theme--light.Connect-wallet-modal-v2 .Modal-content .Modal-body .Wallet-btn {
  background: var(--Nature-2, #f2f5f7) !important;
}
