.App-header.theme--light {
  .App-header-balance-container,
  .network-account-container,
  .menu-items,
  .connect-wallet-btn {
    border: 1px solid rgba(13, 26, 22, 0.07) !important;
  }

  .App-header-network {
    .network-dropdown:hover {
      background: #fff;
      border-radius: 8px 0 0 8px;
    }
  }
  .show-dropdown-link {
    .menu-item {
      img {
        border-radius: var(--Base-Unit-S-12, 12px);
        background: var(--HighLight, rgba(2, 178, 127, 0.15));
      }
    }
  }
  .App-cta.small.transparent.address-btn {
    &:hover {
      background: none !important;
    }
  }

  .App-header-add-eth {
    background-color: #02b27f;

    &:hover {
      background-color: #03805b;
    }
  }

  .App-header-balance-container {
    background: transparent;
  }
  .App-cta {
    background: #fff !important;
    span {
      color: #0d1a16 !important;
    }
    fill: #0d1a16;
  }
  .network-dropdown-seperator,
  .network-dropdown-seperator {
    background: rgba(13, 26, 22, 0.07);
  }
  .network-dropdown-menu-item {
    &.menu-item-active {
      background: rgba(13, 26, 22, 0.07) !important;
    }
  }

  .App-header-dropdown {
    path {
      fill: #0d1a16;
    }
  }
  .App-header-add-eth {
    path {
      fill: #fff;
    }
  }
  .menu-items {
    background: #fff;
    box-shadow: 4px 0px 24px 0px rgba(0, 0, 0, 0.2);
    span:not(.tag-v2) {
      color: #0d1a16 !important;
    }
  }
  .menu-item {
    &:hover {
      background: #02b27f26 !important;
    }

    .wrap {
      & > span.desc {
        color: #0d1a16b2 !important;
      }
    }
  }
  .connect-wallet-btn {
    &:hover {
      border: 1px solid #02b27f !important;
      span {
        color: #02b27f !important;
      }
      transition: 0.2s;
    }
  }
  .menu-item {
    &.actived {
      background: #02b27f26 !important;
      // .wrap {
      // & > span:not(.desc) {
      //   color: #02b27f !important;
      // }
      // }
    }
  }
  .App-header-link-container {
    // .menu-item img:not(.network-dropdown-icon) {
    //   background: #02b27f26 !important;
    // }
    &:hover {
      span {
        color: #02b27f;
      }
    }
  }
  .head-landing {
    a {
      color: rgba(255, 255, 255, 0.6) !important;
    }
    &:hover {
      a {
        color: #03f5ae !important;
      }
    }
  }
}

.App-header-drawer.theme--light {
  background: var(--Background-Modal_Background, #fff);

  .App-header-add-eth {
    background: #02b27f !important;

    &:hover {
      background: #03805b !important;
    }
  }

  .App-header-eth-balance-container {
    border-left-color: var(--Border, rgba(13, 26, 22, 0.07));
  }

  .App-header-balance-container--mobile {
    background: var(--Background-Modal_Background, #fff);
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
  }

  .App-header-item-container {
    border-bottom: 0.5px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  .App-header-link-container {
    border-bottom: 0.5px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
    a {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      // &.active {
      //   background: var(--Nature-2, #f2f5f7) !important;
      // }
    }
  }

  .App-header-link-container-menu {
    border-bottom: 0.5px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  .App-header-link-row {
    span {
      color: #0d1a16b2 !important;
      font-weight: 500 !important;
    }
  }

  .App-header-link-container-menu {
    &.selected {
      background: var(--Nature-2, #f2f5f7);

      .App-header-link-row {
        span {
          color: #02b27f !important;
        }
      }

      .wrap {
        span:not(.tag-v2) {
          color: var(--Text-Text_Primary, #0d1a16) !important;

          &.desc {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
          }
        }
      }

      .App-header-link-container .active {
        background: #02b27f26 !important;

        .wrap {
          span:not(.desc) {
            color: #02b27f !important;
          }
        }
      }
    }

    a img {
      background: #02b27f26;
    }

    .App-header-link-container {
      border-bottom: none !important;
    }

    &:has(.App-header-link-container .active) {
      .App-header-link-row {
        color: #02b27f !important;

        span {
          color: #02b27f !important;
        }
      }
    }
  }
}
