.theme--light {
  .analytic-v2-container {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);
    .analytic-container-body {
      .desc {
        color: #0d1a16b2;

        a {
          color: #0d1a16b2;
        }
      }
    }
    .tab-container {
      background: var(--Border, rgba(13, 26, 22, 0.07));

      .tab--item {
        &:hover {
          div {
            color: #02b27f;
          }
        }
      }

      .tab--actived {
        background: var(--HighLight, rgba(2, 178, 127, 0.15));

        div {
          color: var(--Primary, #02b27f);
        }
      }
    }
    .banner-wrapper {
      background-image: radial-gradient(100% 579.11% at 100% 0%, #007ecc 0%, #223966 100%);
    }
    .title-styled {
      color: #000 !important;
    }
    .title-banner-styled {
      color: #fff !important;
    }
    .desc-warning {
      color: rgba(13, 26, 22, 0.7) !important;
    }
    .stats-box-group {
      border: 1px solid rgba(13, 26, 22, 0.07) !important;
      background: linear-gradient(139deg, rgba(50, 164, 239, 0.2) 8.79%, rgba(165, 219, 250, 0) 100%) !important;
    }
    .total-stat-label,
    .rdrDayPassive {
      color: rgba(13, 26, 22, 0.7) !important;
    }
    .chart-cell,
    .cricle-chart-cell,
    .inner-chart,
    .header,
    .body-row,
    .react-dropdown-select {
      border: 1px solid rgba(13, 26, 22, 0.07) !important;
    }
    .react-dropdown-select,
    .react-dropdown-select-dropdown,
    .rdrCalendarWrapper {
      background: #fff !important;
    }
    .rdrNextPrevButton {
      border: 1px solid #1d2429 !important;
    }
    .rdrPprevButton {
      i {
        border-color: transparent #1d2429 transparent transparent !important;
      }
    }
    .rdrNextButton {
      i {
        border-color: transparent transparent transparent #1d2429 !important;
      }
    }
    .rdrWeekDays {
      span {
        color: rgb(132, 144, 149) !important;
      }
    }
    .rdrDayNumber {
      span {
        color: #1d2429 !important;
      }
    }
    .rdrMonthAndYearPickers {
      select {
        color: #000 !important;
        background: #fff !important;
        border: 1px solid rgb(52, 73, 94) !important;
      }
    }
    .recharts-layer {
      text {
        fill: #0d1a16b2 !important;
      }
    }

    .custom-tooltip {
      background-color: #5e6673 !important;
      border: none !important;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25) !important;

      .label,
      .value,
      .data,
      .date {
        color: #fff;
      }
    }

    // ::-webkit-scrollbar {
    //   width: 8px !important;
    //   background: rgba(0, 0, 0, 0.05) !important;
    //   border-radius: 100px !important;
    // }

    // ::-webkit-scrollbar-track {
    //   width: 8px !important;
    //   background: rgba(0, 0, 0, 0.05) !important;
    //   border-radius: 100px !important;
    // }

    // ::-webkit-scrollbar-thumb {
    //   width: 8px !important;
    //   background: rgba(0, 0, 0, 0.6) !important;
    //   border-radius: 100px !important;
    // }

    .inner-chart.volume-by-asset-charts {
      .header,
      .body-row {
        border: none !important;
        border-bottom: 1px solid rgba(13, 26, 22, 0.07) !important;
      }
    }
    .pie-chart-custom {
      tspan {
        fill: #000 !important;
      }
    }
  }
}

