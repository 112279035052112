.RouterTab {
  &.theme--light {
    background: #f7fffd;
    background-image: none;

    * {
      color: #0d1a16b2;
    }

    .tab-content-title {
      color: #0d1a16;
    }

    .tab-content-desc {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
    }

    .router {
      .earn-tab.new-tag::after {
        color: var(--Functional-Orange, #d49007);
        border: 1px solid #d49007;
      }
    }

    @media screen and (max-width: 767px) {
      .router {
        background: #fff !important;

        .earn-tab.earn-tab-active {
          background: var(--Border, rgba(13, 26, 22, 0.07));
        }
      }
    }

    .OverviewV2 {
      .main-content {
        &.left-content {
          .left-box {
            border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
            background: var(--Nature-1, #fff);

            .left-box-heading {
              .left-box-token {
                .left-box-token-info {
                  .left-box-token-info-symbol {
                    color: #0d1a16;
                  }

                  .left-box-token-info-price {
                    color: rgba(13, 26, 22, 0.7);

                    span {
                      color: #0d1a16;
                    }
                  }
                }
              }
            }

            .left-box-body {
              .left-box-body-left {
                .left-box-body-left-box {
                  .left-box-body-left-box-title {
                    color: #0d1a16b2;
                  }

                  .left-box-body-left-box-value {
                    color: #02b27f;

                    .Tooltip-handle {
                      color: #02b27f !important;

                      div {
                        color: #02b27f !important;
                      }
                    }
                  }
                }
              }

              .left-box-body-right {
                .left-box-body-right-row {
                  .left-box-body-right-row-title {
                    color: #0d1a16b2;
                  }

                  .left-box-body-right-row-value {
                    color: #0d1a16;

                    .value {
                      color: #0d1a16;
                    }

                    span {
                      color: #0d1a16b2;
                    }
                  }
                }
              }
            }
          }
        }

        &.right-content {
          border-radius: 16px !important;
          border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
          background: radial-gradient(285.88% 111.8% at 100% 0%, #f2fffb 0%, #daf2eb 100%) !important;

          *:not(button, .Tooltip-popup) {
            color: #0d1a16b2 !important;
          }

          .right-box {
            &.right-box-bottom {
              border-color: rgba(13, 26, 22, 0.07);
            }

            .actions {
              button.default-btn {
                background: #02b27f;
                color: #fff;
              }

              button.border-btn {
                color: #0d1a16 !important;
                border-color: rgba(13, 26, 22, 0.07) !important;
                &:hover {
                  border: 1px solid #02b27f !important;
                  color: #02b27f !important;
                }
              }
            }

            .right-box-heading {
              .right-box-heading-title {
                color: #0d1a16 !important;
              }

              .right-box-heading-reward div {
                color: #02b27f !important;
              }
            }

            .right-box-rows {
              .right-box-row {
                .right-box-row-value {
                  color: #0d1a16 !important;

                  .span-html {
                    color: #0d1a16 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
