.modal-mint {
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(35px);
  .Modal-content {
    background: rgba(255, 255, 255, 0.05) !important;
    border: 2px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(35px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 36px !important;
    .Modal-body {
      margin-top: 0;
    }
    .Modal-title-bar {
      margin-bottom: 0;
    }
    max-width: 872px;
    @media screen and (max-width: 768px) {
      max-width: calc(100vw - 32px);

      .Modal-body {
        max-height: 90vh;
      }
    }

    .divider {
      display: none;
    }
  }

  .Modal-content .Modal-body {
    border: none;
  }
}
