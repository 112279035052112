.bridge-wrapper.theme--light {
  .page-title {
  }

  .page-desc {
    color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
  }

  .power-by {
    color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
  }

  .form-bridge {
    background: var(--Nature-1, #fff);
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

    .chains {
      .swap-ball {
        background: #02b27f;
        svg {
          path {
            fill: #fff;
          }
        }

        &:hover {
          background: rgba(3, 128, 91, 1) !important;
        }

        &:active {
        }
      }

      .chain-select-dropdown {
        .dropdown-name {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
        }

        .selected-chain {
          border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

          .chain-info {
          }
        }

        .menu-items {
          box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.15);
          background: #fff !important;

          .menu-item.menu-item--active,
          .menu-item:hover {
            background: var(--Nature-2, #f2f5f7) !important;
          }

          .chain-name {
            color: var(--Text-Text_Primary, #0d1a16) !important;
          }
        }
      }
    }

    .transfer-amount {
      input {
        background: var(--Nature-3, #fff);
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
        color: var(--Text-Text_Primary, #0d1a16) !important;

        &::placeholder {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
        }
      }

      .balance-info {
        div:not(.balance) {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
        }

        .balance {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

          span {
            color: var(--Text-Text_Primary, #0d1a16) !important;
          }
        }
      }
    }

    .white-text {
      color: var(--Text-Text_Primary, #0d1a16) !important;
    }

    .recieve-info {
      div:first-child {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }

      span.Tooltip-handle {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }
    }

    .actions {
      button {
      }
    }
  }

  .down-content {
    .icon-view {
    }

    .transaction-table-mobile {
      .table-mobile-content,
      .no-data {
        background: #fff;
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

        .title {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }

        .value {
          color: var(--Text-Text_Primary, #0d1a16) !important;
        }
      }
    }

    .pagination {
      .btn-primary {
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      }
    }

    .transaction-table {
      .transaction-header {
        .table-column {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

          &:first-child {
          }

          &:last-child {
          }
        }
      }

      .transaction-row {
        background: #fff !important;

        .table-column {
          &.delivered {
            color: var(--Primary, #02b27f) !important;
          }

          &.failed {
            color: var(--Functional-Red, #d62f44);
          }

          &.processing {
            color: var(--Functional-Orange, #d49007);
          }

          &.blocked {
            color: #595959;
          }
        }
      }

      .transaction-content {
        .no-data {
          background: #fff;
        }
      }

      .transaction-line {
        .table-column {
          &:last-child {
          }
        }
      }

      .transaction-row {
      }
    }
  }

  .reciever {
    .address-text {
      color: #02b27f;
    }
  }

  .tabs-container {
    background: #fff;
    .tab-content {
      span {
      }
    }

    .tab-content-active {
      background: var(--Border, rgba(13, 26, 22, 0.07));
    }
  }

  .nft-select-container {
    .available {
      span {
      }
    }

    .info {
      .selected {
        span {
        }
      }

      .select-btn {
      }
    }
  }

  .actions {
    button {
    }
  }

  .nft-list {
    .list-item-selected {
      border: 1px solid var(--Primary, #02b27f);
    }

    .list-item {
      background: var(--Nature-1, #fff);

      .nft-info {
        .title {
        }

        .info {
          span {
          }
        }
      }
    }
  }

  .empty-state {
    background: #fff;

    .empty-img {
      width: 160px;
      height: 160px;
      filter: grayscale(1);
    }

    .get-your-nft {
      color: var(--Primary, #02b27f) !important;
    }
  }

  .nft-selected-container {
    .nft-selected-item {
      .main-nft {
      }

      .ic-close {
        img {
        }
      }

      .overlay {
      }
    }

    .add-nft-item {
      border: 2px dashed var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Border, rgba(13, 26, 22, 0.07));
    }
  }

  .menu-items {
    .menu-item {
      .chain-name {
      }
    }

    .menu-item--active {
    }
  }
}
