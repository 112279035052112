.BeginAccountTransfer.theme--light {
  .text-input {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
    background: var(--Nature-1, #fff);
  }

  .Page-content {
    .input-label {
      span {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }
    }
  }

  .Page-description {
    span {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
    }
  }
}
