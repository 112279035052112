.asset-menu-items.theme--light {
  border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
  background: var(--Nature-1, #fff) !important;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.07);

  .asset-item {
    color: #0d1a16 !important;
    &:hover {
      background: #f2f5f7 !important;
      span {
        color: #000 !important;
      }
    }
  }
}
