.css-j1tvsc-bridgeContent {
  max-width: 100% !important;
}

.css-11ki0a5-appContent {
  padding: 0px !important;
  font-size: 16px !important;
  .MuiSelect-select {
    font-size: 16px !important;
    padding: 12px 32px 12px 14px !important;
    display: flex;
    align-items: center;
  }
}

.css-1sv8cli {
  font-size: 16px !important;
}

.css-opkrz7-container {
  margin-top: 50px !important;
  font-size: 14px !important;
}

.css-vubbuv {
  font-size: 22px !important;
}

.css-qxu2nm {
  font-size: 14px !important;
}

.css-o2y64n-input {
  font-size: 18px !important;
  padding: 8px 0px !important;
}

.css-1pl2bhg-tab {
  font-size: 14px !important;
}

.css-1y3ivw0-multiLineChip,
.css-9mwlbc-multiLineChip {
  font-size: 12px !important;
}

.css-3dluyq-routeTitle {
  font-size: 15px !important;
}

.css-wkojpu-price {
  font-size: 12px !important;
}

.css-30z2fb-dropdownItem {
  font-size: 16px !important;
}

.css-a5dm9k-container {
  padding: 12px 16px !important;
}

.css-yop3gh {
  font-size: 20px !important;
}

.css-1xsto0d {
  font-size: 14px !important;
}

.css-1fgn3s2-action {
  background-color: #2b996b !important;
  color: white !important;
}

.css-1fylc76-addToken {
  font-size: 14px !important;
}

.css-1ucts4o-container {
  width: 22px !important;
  height: 22px !important;
}

// div:has(> *:contains("Transaction complete")) {
//   background: #08ffa3 !important;
//   color: black !important;
//   padding: 6px 14px !important;
//   border-radius: 17px !important;
// }

// div:-webkit-any(div:contains("Transaction complete")) {
//   background: #08ffa3 !important;
//   color: black !important;
//   padding: 6px 14px !important;
//   border-radius: 17px !important;
// }
