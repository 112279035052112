.Tab.theme--light {
  background-color: transparent;

  .Tab-option {
    color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

    &:not(.active):hover {
      background: rgba(2, 178, 127, 0.15);
    }

    &.active {
      background: var(--HighLight, rgba(2, 178, 127, 0.15));
      color: var(--Primary, #02b27f);
    }
  }
}
