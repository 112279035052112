.ValidationRow {
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: var(--font-base);
}

.ValidationRow-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ValidationRow-icon {
  margin-right: 1.5rem;
}

.BeginAccountTransfer {
  max-width: unset !important;
  margin: 0 !important;
  padding-top: 65px !important;
  padding-bottom: 0 !important;
  min-height: unset !important;
}

.BeginAccountTransfer > .Page-title-section {
  padding-left: 0;
  padding-right: 0;
}

.BeginAccountTransfer > .Page-content {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 600px) {
  .BeginAccountTransfer {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: unset !important;
  }

  .BeginAccountTransfer .input-row {
    margin-bottom: 0;
  }

  .BeginAccountTransfer > .Page-title-section {
    padding-left: 16px;
    padding-right: 16px;
  }

  .BeginAccountTransfer > .Page-title-section > .Page-title {
    margin-bottom: 8px;
  }

  .BeginAccountTransfer > .Page-content {
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;
  }
}

.BeginAccountTransfer .ValidationRow {
  margin-bottom: 1.5rem;
  font-size: var(--font-base);
}

.BeginAccountTransfer-validations {
  margin-bottom: 24px;
}

.BeginAccountTransfer .Modal .App-cta {
  display: block;
  text-align: center;
  font-size: var(--font-base);
}
