.CompleteAccountTransfer .Modal .App-cta {
  display: block;
  text-align: center;
}
.CompleteAccountTransfer .Page-description {
  display: block !important;
}
@media screen and (max-width: 767px) {
  .CompleteAccountTransfer .Page-title-section {
    padding: 0 16px;
  }
}
.CompleteAccountTransfer .Page-description p span {
  color: #02f5ae;
}
.CompleteAccountTransfer .Page-title span {
  font-size: 24px;
}

.CompleteAccountTransfer.theme--light .Page-description p span {
  color: #02b27f;
}
