.bridge-wrapper {
  padding-bottom: 286px;
  max-width: 100%;
  width: 872px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
  min-height: 900px;

  .BeginAccountTransfer-validations {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .ValidationRow {
    margin-bottom: 12px;
  }

  .page-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }

  .page-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 8px;
  }

  .power-by {
    color: rgba(255, 255, 255, 0.6);
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 88.24px;
      height: 24px;
    }
  }

  .form-bridge {
    background: #191b2e;
    border-radius: 16px;
    margin-top: 24px;
    padding: 24px;
    position: relative;
    border: 1px solid transparent;

    .chains {
      display: flex;
      align-items: center;
      gap: 16px;

      .swap-ball {
        background: #03f5ae;
        border-radius: 14px;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        cursor: pointer;

        svg {
          color: #080715;
        }

        &:hover {
          color: #080715;
          background: #04d397;
        }

        &:active {
          color: #080715;
          background: #04d397;
        }
      }

      .chain-select-dropdown {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 8px;
        position: relative;

        .dropdown-name {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: rgba(255, 255, 255, 0.6);
        }

        .selected-chain {
          position: relative;
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          padding: 12px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500 !important;
          font-size: 16px !important;
          line-height: 140% !important;
          cursor: pointer;

          .chain-info {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
        gap: 12px;

        .chain-select-dropdown {
          width: 100%;
        }

        .swap-ball {
          margin-top: 0;
          transform: rotate(90deg);
        }
      }
    }

    .transfer-amount {
      // margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      input {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        width: 100%;
        height: 46px;
        background: #11121b;
        padding: 12px 16px;
        /* White/10 */

        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.60);
          font-weight: 400;
        }
      }

      .balance-info {
        display: flex;
        justify-content: space-between;
        color: rgba(255, 255, 255, 0.6);

        span {
          color: white;
        }

        .balance {
          cursor: pointer;
        }
      }
    }

    .recieve-info {
      margin-top: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.6);
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .actions {
      margin-top: 24px;
      width: 100%;

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin: 0;
      }
    }

    @media screen and (max-width: 700px) {
      padding: 16px;

      .recieve-info {
        .reciever {
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          img {
            display: flex;
          }

          span {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }
  }

  .down-content {
    margin-top: 64px;

    .icon-view {
      width: 24px;
      height: 24px;
    }

    .transaction-table-mobile {
      display: none;
    }

    .transaction-table {
      margin-top: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      .transaction-header {
        color: rgba(255, 255, 255, 0.7);
        padding: 0 16px;

        .table-column {
          padding-left: 6px;

          &:first-child {
            padding: 0;
          }

          &:last-child {
            padding: 0;
          }
        }
      }

      .transaction-line {
        display: flex;

        .table-column {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 8px;

          &:last-child {
            flex: none;
            width: fit-content;
          }
        }
      }

      .transaction-row {
        background: #191b2e;
        border-radius: 12px;
        padding: 12px 16px;
        margin-top: 8px;
        // cursor: pointer;
      }
    }

    @media screen and (max-width: 700px) {
      .transaction-table {
        display: none;
      }

      .transaction-table-mobile {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 16px;

        .table-mobile-content {
          background: #191b2e;
          border-radius: 16px;
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .content-line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;

            .title {
              color: rgba(255, 255, 255, 0.6);
            }

            .value {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 871px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (max-width: 700px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .tabs-container {
    margin-top: 24px;
    display: flex;
    padding: 8px;
    align-items: flex-start;
    border-radius: 16px;
    background: #191b2e;
    width: fit-content;

    .tab-content {
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 12px;
      text-decoration: none;

      @media screen and (max-width: 450px) {
        padding: 12px !important;
      }

      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }

    .tab-content-active {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .nft-select-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .available {
      color: rgba(255, 255, 255, 0.6);

      span {
        color: white;
        font-weight: 700;
      }
    }

    .info {
      display: flex;
      align-items: center;
      gap: 12px;

      @media screen and (max-width: 400px) {
        gap: 8px;
      }

      .selected {
        color: rgba(255, 255, 255, 0.6);

        span {
          color: white;
          font-weight: 700;
        }
      }

      .select-btn {
        cursor: pointer;
        color: #03f5ae;
      }
    }
  }

  .actions {
    margin-top: 24px;

    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      margin: 0;
    }
  }

  .nft-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    gap: 12px;
    max-height: 240px;
    overflow-y: auto;
    padding-right: 10px;

    // &::-webkit-scrollbar {
    //   width: 8px !important;
    //   background: #191b2e !important;
    //   border-radius: 100px !important;
    // }

    // &::-webkit-scrollbar-track {
    //   width: 8px !important;
    //   background: #191b2e !important;
    //   border-radius: 100px !important;
    // }

    // &::-webkit-scrollbar-thumb {
    //   width: 8px !important;
    //   background: #4d527c;
    //   border-radius: 100px !important;
    // }

    @media screen and (max-width: 700px) {
      padding-right: 8px;
    }

    .list-item-selected {
      border: 1px solid #03f5ae;
    }

    .list-item {
      width: calc(50% - 6px);
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px;
      background: #191b2e;
      border-radius: 12px;
      cursor: pointer;

      @media screen and (max-width: 700px) {
        width: 100% !important;
      }

      .nft-info {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        .info {
          opacity: 0.6;

          span {
            font-size: 12px;
            line-height: 140%;
          }
        }
      }
    }
  }

  .empty-state {
    width: 100%;
    display: flex;
    height: 272px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 16px;
    background: #191b2e;

    .empty-img {
      width: 160px;
      height: 160px;
      filter: grayscale(1);
    }

    .get-your-nft {
      text-decoration: none;
      color: #03f5ae;

      img {
        margin-left: 4px;
      }
    }
  }

  .nft-selected-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .nft-selected-item {
      position: relative;

      .main-nft {
        border-radius: 12px;
        width: 64px;
        height: 64px;
        position: relative;
        z-index: 1;
      }

      .ic-close {
        position: absolute;

        top: -2px;
        right: -2px;
        z-index: 3;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
        }
      }

      .overlay {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 12px;
      }
    }

    .add-nft-item {
      width: 64px;
      cursor: pointer;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.1);
      border: 2px dashed rgba(255, 255, 255, 0.1);
    }
  }
}

.white-text {
  color: white !important;
}

.address-text {
  color: #03f5ae;
  text-decoration: underline;

  @media screen and (max-width: 385px) {
    font-size: 13px;
  }
}

.reciever {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  img {
    margin-left: 4px;
  }
}

.bridge-wrapper {
  .menu-items {
    top: 8.3rem;

    .menu-item {
      display: flex;
      align-items: center;
      gap: 8px;

      .chain-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: white;
      }
    }

    .menu-item--active {
      background: #808aff14 !important;
      opacity: 1;
      color: #eee;
    }
  }
}

.delivered {
  color: #03f5ae;
  text-transform: capitalize;
}

.pending_claim {
  color: #ffdf76;
  text-transform: capitalize;
}

.blocked {
  color: #595959;
  text-transform: capitalize;
}

.inflight {
  color: #ffdf76;
  text-transform: capitalize;
}

.failed {
  color: #f6475d;
  text-transform: capitalize;
}

.processing {
  color: #ffdf76;
  text-transform: capitalize;
}

.no-data {
  margin-top: 20px;
  padding: 12px 16px;
  background: #191b2e;
  border-radius: 12px;

  @media screen and (max-width: 700px) {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
}

.green-text {
  color: #03f5ae;
}

.cusor-pointer {
  cursor: pointer;
}

.main-nft-modal {
  width: 48px;
  height: 48px;
  border-radius: 12px;
}

.nft-tooltip-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
