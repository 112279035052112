.Confirmation-box-wrapper {
  padding: 16px 12px;
  border-radius: 16px;
  background: #191b2e;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }
}
.Confirmation-box-wrapper-icon {
  width: 32px;
  height: 32px;
}
.Exchange-desc-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 12px 0px 24px;
}
.warning-box-wrapper {
  button {
    width: 100px;
    margin-top: 0 !important;
  }
}
