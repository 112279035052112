.earn-content-container.theme--light {
  .green-value {
    color: var(--Primary, #02b27f) !important;
  }

  .box-divider {
    background: var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  .tab-main-content {
    .left-content {
      .left-content-border-box {
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

        .box-apr {
          color: var(--Primary, #02b27f) !important;
        }

        .box-main-price {
          background: radial-gradient(285.88% 111.8% at 100% 0%, #f2fffb 0%, #daf2eb 100%);

          .price-info {
            .title {
              color: rgba(13, 26, 22, 0.7);
            }

            .value {
              color: #0d1a16;
            }
          }
        }

        .box-devider .divider-title {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
        }

        .box-apr {
          .Tooltip-handle {
            color: var(--Primary, #02b27f);
          }
        }

        .box-token-info {
          .title,
          .value span {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
          }
        }
      }

      .left-box-content {
        background: #fff;
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

        .box-desc,
        .reward-info .title {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }

        .lock {
          :not(.label) {
            color: var(--Text-Text_Primary, #0d1a16) !important;
          }

          .label {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
          }
        }

        .button-actions {
          button.default-btn {
            div {
              color: #fff;
            }
          }
        }
      }
    }

    .right-content {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

      .left-box-content {
        background: #fff;

        & + .left-box-content {
          border-top: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
        }

        .main-title {
          .main-desc {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
          }
        }

        .rewards-container {
          .reward-info {
            .title {
              .Tooltip-handle {
                color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
              }
            }

            & > div:last-child {
              span {
                color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
              }
            }
          }
        }

        .desc {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }

        .vest-container {
          .vest-box {
            .vest-info {
              .vest-box-title,
              .vest-box-inUsd {
                color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
              }

              .vest-box-amount {
                color: var(--Text-Text_Primary, #0d1a16);
              }
            }
          }
        }
      }
    }
  }
}
