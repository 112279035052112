.theme--light {
  .swap-v2-wrapper {
    .Exchange {
      background: none !important;
    }
    .Exchange-left {
      background: none !important;

      &:has(.TradeHistory.with-scroll) {
        overflow-y: hidden;
      }

      .section-with-scroll {
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
    }
    .chart-container-border {
      background: #fff !important;
    }
    .ExchangeChart,
    .box-trade-swap,
    .Exchange-list {
      background-color: #fff !important;
      // box-shadow: 0 2px 4px #0003;
      border: 1px solid rgba(13, 26, 22, 0.07);
    }
    .Exchange-list {
      border-radius: 16px;
    }
    .chart-container-border {
      background: #fff !important;
    }
    .Exchange-right {
      background: none !important;
    }
    .Tab-option {
      color: rgba(13, 26, 22, 0.7);
      &.active {
        background: rgba(13, 26, 22, 0.07);
        color: #0d1a16;
      }
    }
  }
}
