.buy-glp-content {
  padding-top: 4.65rem;
}

.Page-title {
  font-weight: 700 !important;
  line-height: 140% !important;
}

.Page-desc {
  color: var(--white-60, var(--text-text-secondary, rgba(255, 255, 255, 0.6)));
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

@media (max-width: 1024px) {
  .Page-title {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 140% !important;
  }
}
