.card {
  background: #191b2e;
  border-radius: 16px;
}

.card-header {
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
}

.card-divider {
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}
.card-body {
  padding: 24px;
}
