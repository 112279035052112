.earn-content-container {
  // margin-top: 48px;
  flex: 1;

  // @media screen and (max-width: 1023px) {
  //   margin-top: 0;
  // }

  .btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    margin-top: 16px;

    button {
      font-weight: 700;
    }

    &.no-chain {
      grid-template-columns: 1fr;
    }
  }

  .lock {
    display: flex;
    padding: var(--base-unit-xs-8, 8px);
    align-items: center;
    gap: var(--Border_radius, 4px);
    align-self: stretch;
    border-radius: var(--base-unit-xs-8, 8px);
    background: var(--white-10, rgba(255, 255, 255, 0.1));
    // line-height: 140%; /* 19.6px */
    span {
      font-weight: 700;
      color: var(--white-100, #fff);
    }
    margin-top: 16px;
    .lock-content {
      display: flex;
      align-items: center;
      gap: 4px;
      .label {
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    @media screen and (max-width: 767px) {
      align-items: flex-start;

      .lock-content {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .green-value {
    color: var(--green, #03f5ae);
    font-size: 16px;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
  }

  &.custom {
    // min-height: 1000px;

    // @media (max-width: 1024px) {
    //   min-height: 1600px;
    // }

    .reward-info {
      .Tooltip-popup {
        max-width: 300px;
        // white-space: wrap !important;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }

    .heading {
      display: flex;
      align-items: flex-end;
      gap: 24px;
      align-self: stretch;

      .actions {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        margin-left: auto;

        button {
          display: flex;
          height: 40px;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-color: #ffffff1a;
        }
      }

      @media screen and (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start;

        .actions {
          margin-left: 0;
        }
      }
    }
  }

  .tab-content-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    &:has(.tag) {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .tag {
      display: flex;
      padding: 2px 12px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: var(--laverage-background, #3c2601);

      color: var(--laverage-text, #f0b90b);
      font-size: 20px;
      font-weight: 700;
      line-height: 140%; /* 28px */
    }
  }

  .tab-content-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 8px;
  }

  .tab-main-content {
    margin-top: 24px;
    display: flex;
    gap: 24px;

    @media screen and (max-width: 1023px) {
      .left-content {
        order: 2;
      }
      .right-content {
        order: 1;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 16px;
    }

    .main-content {
      flex: 1;
    }

    .right-content {
      border-radius: 16px !important;
      height: fit-content;

      &.no-tooltip {
        .left-box-content {
          &:first-child {
            border-radius: 16px 16px 0 0 !important;
          }
          &:last-child {
            border-radius: 0 0 16px 16px !important;

            @media screen and (max-width: 767px) {
              margin-top: 0 !important;
            }
          }
        }
      }

      .left-box-content {
        background: var(--v-3-natural-1, #191b2e);
        // border-radius: 16px;
        padding: 24px;

        .vest-container {
          display: flex;
          align-items: stretch;
          gap: var(--Base-Unit-L-24, 24px);
          align-self: stretch;
          margin-top: 16px;

          button {
            font-weight: 700;
          }

          .vest-box {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;
            align-items: stretch;

            .vest-info {
              .vest-box-title {
                color: var(--White-60, rgba(255, 255, 255, 0.6));
                font-size: 14px;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
                margin-bottom: 8px;
                .Tooltip-handle {
                  // color: #0d1a16b2;
                }
              }

              .vest-box-amount {
                color: var(--White-100, #fff);
                font-size: 14px;
                font-weight: 700;
                line-height: 140%; /* 19.6px */
                margin-bottom: 4px;

                &:has(img) {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                }
              }

              .vest-box-inUsd {
                color: var(--White-60, rgba(255, 255, 255, 0.6));
                font-size: 14px;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
              }
            }
          }

          .box-divider {
            width: 1px;
            align-self: stretch;
            background: var(--White-10, rgba(255, 255, 255, 0.1));
          }

          // @media screen and (max-width: 767px) {
          //   display: grid;
          //   grid-template-columns: 1fr 1px 1fr 1px 1fr;
          // }
        }

        &:first-child {
          margin-top: 24px;
        }

        & + .left-box-content {
          border-top: 1px solid var(--White-10, rgba(255, 255, 255, 0.1)) !important;
        }

        .iconn {
          width: 200px;
          height: 200px;
          position: absolute;
          right: 0px;
          top: 0;
        }

        &:has(.iconn) {
          position: relative;
        }

        &:first-child {
          margin-top: 0;
        }

        .main-title-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .main-title:has(.bootstrap) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
          }

          .bootstrap {
            color: var(--laverage-text, #f0b90b);
            font-size: 14px;
            font-weight: 700;
            line-height: 120%; /* 16.8px */
            display: flex;
            padding: 2px 8px;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            background: var(--laverage-background, #3c2601);
          }
        }

        .main-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 140%;

          > div {
            margin-top: 8px;
            font-size: 24px;

            &.main-desc {
              color: var(--White-60, rgba(255, 255, 255, 0.6));
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }
          }

          &.flex {
            display: flex;
            align-items: flex-start;
            gap: 8px;

            .fee-discount {
              display: flex;
              padding: 4px 8px;
              align-items: flex-start;
              gap: 8px;
              border-radius: 8px;
              background: var(--yellow, #ffce57);
              color: var(--natural-1, #1a1919);
              font-size: 14px;
              font-weight: 400;
              line-height: 110%; /* 16.8px */

              span {
                font-weight: 700;
              }
            }

            @media screen and (max-width: 767px) {
              flex-direction: column;
            }
          }
        }

        .rewards-container {
          display: flex;
          margin-top: 16px;

          @media screen and (max-width: 767px) {
            margin-top: 16px;
          }

          .reward-info {
            // width: 100%;

            .Tooltip-popup {
              width: fit-content;
              word-break: keep-all;
              min-width: unset;
              white-space: nowrap;
            }

            .title {
              color: rgba(255, 255, 255, 0.6);
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              margin-bottom: 8px;
            }

            .value {
              font-weight: 700;
              font-size: 14px;
              line-height: 140%;
              margin-top: 8px;

              span {
                font-weight: 400;
                color: rgba(255, 255, 255, 0.6);
                font-size: 14px;
              }
            }
          }

          &.grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;

            @media screen and (max-width: 767px) {
              row-gap: 16px;
            }
          }
        }

        .box-desc {
          color: rgba(255, 255, 255, 0.6);
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          margin-top: 8px;
        }

        .rewards-es-container {
          display: flex;
          margin-top: 16px;

          .account-info {
            flex: 1;
            padding-right: 16px;

            .reward-info {
              margin-top: 16px;

              &:first-child {
                margin-top: 0;
              }

              .title {
                color: rgba(255, 255, 255, 0.6);
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                margin-bottom: 8px;
              }

              .value {
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                margin-top: 8px;

                span {
                  font-weight: 400;
                  font-size: 14px;
                  color: rgba(255, 255, 255, 0.6);
                }
              }
            }
          }

          .button-action button {
            min-width: 126px;
          }

          .button-actions {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: stretch;
            gap: 12px;

            button {
              min-width: 126px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              margin: 0 !important;
              border-color: #ffffff1a;
            }

            @media screen and (max-width: 767px) {
              display: grid;
              grid-template-columns: 1fr;
              grid-auto-rows: 40px 40px;
              gap: 12px;
              margin-top: auto;

              button {
                height: 40px;
              }
            }
          }

          @media screen and (max-width: 767px) {
            // flex-direction: column;
            gap: 24px;

            // .button-actions {
            //   grid-template-columns: 1fr 1fr;
            //   grid-template-rows: unset;
            // }
          }
        }

        .total-amount {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          margin-top: 24px;

          @media screen and (max-width: 767px) {
            margin-top: 16px;
          }

          .title {
            color: var(--white-100, #fff);
            font-size: 16px;
            font-weight: 700;
            line-height: 120%; /* 19.2px */
          }

          .value {
            color: var(--white-100, #fff);
            font-size: 16px;
            font-weight: 700;
            line-height: 120%; /* 19.2px */
          }
        }

        .persent-wrapper {
          display: flex;
          align-items: flex-start;
          gap: 4px;
          align-self: stretch;
          margin-top: 8px;
        }

        .stats {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          align-self: stretch;
          margin-top: 24px;

          @media screen and (max-width: 767px) {
            margin-top: 16px;
          }

          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .title {
              color: var(--white-60, rgba(255, 255, 255, 0.6));
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }

            .value {
              color: var(--white-100, #fff);
              font-size: 16px;
              font-weight: 500;
              line-height: 140%; /* 22.4px */
            }
          }
        }

        button.action-btn {
          width: 100%;
          margin-top: 24px;

          @media screen and (max-width: 767px) {
            margin-top: 16px;
          }
        }

        .line-divider {
          width: 100%;
          margin: 24px 0;
          height: 1px;
          background: var(--white-10, rgba(255, 255, 255, 0.1));

          @media screen and (max-width: 767px) {
            margin: 16px 0;
          }
        }

        .total-reward {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;

          .title {
            color: var(--white-100, #fff);
            font-size: 16px;
            font-weight: 700;
            line-height: 120%; /* 19.2px */
          }

          .value {
            display: flex;
            align-items: baseline;
            gap: 4px;
            color: var(--white-100, #fff);
            font-size: 16px;
            font-weight: 700;
            line-height: 120%; /* 19.2px */

            span {
              color: var(--white-60, rgba(255, 255, 255, 0.6));
              font-size: 14px;
              font-weight: 400;
              line-height: 120%; /* 16.8px */
            }
          }
        }
      }

      .right-content-box {
        margin-top: 16px;

        &:first-child {
          margin-top: 0;
        }

        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 16px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .box-info {
          width: calc(50% - 38px);

          .title {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: rgba(255, 255, 255, 0.6);
          }

          .value {
            margin-top: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;

            .box-apr {
              font-weight: 700;
              font-size: 16px;
              line-height: 140%;
              color: #03f5ae;
              margin-top: 8px;
            }

            .tooltip-apr {
              .Tooltip-handle {
                color: #03f5ae !important;
              }
            }
            @media screen and (max-width: 768px) {
              .tooltip-apr {
                .Tooltip-popup {
                  &:not(.right-bottom) {
                    left: -150px !important;
                  }

                  &.right-bottom {
                    left: unset !important;
                    right: 0 !important;
                  }
                }
              }
            }

            .Tooltip {
              margin-left: 0 !important;

              .Tooltip-handle {
                margin-left: 0 !important;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                color: white;
              }
            }

            .sub-value {
              font-weight: 400;
              color: rgba(255, 255, 255, 0.6);
              margin-left: 4px;
              font-size: 14px;
              margin-left: 4px;
            }
          }
        }
        @media screen and (max-width: 700px) {
          margin-top: 16px;
          flex-wrap: wrap;
          .box-info {
            width: 50%;
          }
          // .border-btn {
          //   width: 100% !important;
          //   margin: 16px 0 0 0 !important;
          //   justify-content: center;
          // }
        }
      }
    }

    .left-content {
      .left-content-border-box {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 16px;
        padding: 24px;

        .box-main-price {
          padding: 16px;

          align-self: stretch;
          border-radius: 16px;
          background: radial-gradient(285.88% 111.8% at 100% 0%, rgba(0, 46, 88, 0.8) 0%, rgba(25, 27, 46, 0.8) 100%);
          position: relative;
          overflow: hidden;

          .price-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            .title {
              color: var(--white-60, var(--text-text-secondary, rgba(255, 255, 255, 0.6)));
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 16.8px */
            }

            .value {
              color: var(--white-100, #fff);
              font-size: 16px;
              font-weight: 700;
              line-height: 140%; /* 22.4px */
            }
          }
          img {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            right: 8px;
            // width: 100px;
            // height: 100px;
            justify-content: center;
            align-items: center;
          }
        }

        .box-token-info-wrapper {
          margin-top: 24px;

          @media screen and (max-width: 767px) {
            margin-top: 16px;
          }
        }

        .buy-btn-container {
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;

          @media screen and (max-width: 767px) {
            margin-top: 16px;
          }

          a {
            flex: 1;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;

            &:hover {
              color: #666 !important;
            }
          }
        }

        .box-devider {
          display: flex;
          align-items: center;
          margin-top: 24px;

          @media screen and (max-width: 767px) {
            margin-top: 16px;
          }

          .divider-title {
            color: rgba(255, 255, 255, 0.6);
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
          }

          .divider-line {
            flex: 1;
            height: 1px;
            background: rgba(255, 255, 255, 0.1);
          }
        }

        .box-apr {
          color: var(--green, #03f5ae);
          font-size: 16px;
          font-weight: 700;
          line-height: 140%; /* 33.6px */
          margin-top: 8px;
        }

        .box-token-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;

          .title {
            color: rgba(255, 255, 255, 0.6);
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: rgba(255, 255, 255, 0.6);
          }

          .value {
            font-size: 14px;
            font-weight: 700;
            line-height: 22.4px;

            & > span {
              color: var(--White-60, rgba(255, 255, 255, 0.6));
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }
          }
        }

        .box-stake {
          display: flex;
          align-items: flex-end;
          gap: 24px;
          align-self: stretch;
          margin-top: 24px;

          .box-left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            flex: 1 0 0;

            .box-content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;

              .title {
                color: var(--white-60, rgba(255, 255, 255, 0.6));
                font-size: 14px;
                font-weight: 400;
                line-height: 120%; /* 16.8px */
              }

              .value {
                color: var(--white-100, #fff);
                font-size: 16px;
                font-weight: 700;
                line-height: 120%; /* 19.2px */

                span {
                  color: var(--white-60, rgba(255, 255, 255, 0.6));
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 120%; /* 16.8px */
                  margin-left: 4px;
                }
              }
            }
          }

          .box-right {
            display: flex;
            width: 126px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;

            &.disconnected {
              width: unset;
            }

            button {
              display: flex;
              height: 40px;
              padding: 8px 16px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              align-self: stretch;
              border-color: #ffffff1a;
            }
          }

          @media screen and (max-width: 767px) {
            margin-top: 16px;
            flex-direction: column;
            gap: 24px;
            align-items: stretch;

            .box-right {
              width: unset;
              flex-direction: row;
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
          }
        }

        .box-total {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          margin-top: 24px;

          @media screen and (max-width: 767px) {
            margin-top: 16px;
          }

          .title {
            color: #fff;
            line-height: 120%; /* 16.8px */
            font-size: 16px;
            font-weight: 700;
          }

          .value {
            color: var(--white-100, #fff);
            font-size: 16px;
            font-weight: 700;
            line-height: 120%; /* 19.2px */

            span {
              color: var(--white-60, rgba(255, 255, 255, 0.6));
              font-size: 14px;
              font-weight: 400;
              line-height: 120%; /* 16.8px */
              margin-left: 4px;
            }
          }
        }

        @media screen and (max-width: 767px) {
          padding: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 1033px) {
    .tab-main-content {
      flex-direction: column-reverse;

      .main-content {
        flex: none;
        width: 100%;
      }
    }

    // padding-bottom: 120px;
  }

  @media screen and (max-width: 600px) {
    margin-top: 0;
    padding-left: 16px;
    padding-right: 16px;
    // padding-bottom: 280px;
    .left-box-content {
      padding: 16px !important;
      &:not(:first-child) {
        // margin-top: 0 !important;
      }
    }
    .stake-box-content {
      margin-top: 16px !important;
    }
    .right-content-border-box {
      padding: 16px !important;
    }
    .rewards-es-container {
      flex-direction: column;
      gap: 16px;

      .button-actions {
        display: flex;
        justify-content: space-between;
        flex-direction: row !important;
      }

      @media screen and (max-width: 767px) {
        flex-direction: row;

        .button-actions {
          width: 120px;
          display: flex;
          justify-content: space-between;
          flex-direction: column !important;
        }
      }
    }
  }
}
.box-apr .Tooltip-popup {
  right: unset !important;
  left: 0 !important;
}

.box-info {
  .Tooltip-popup {
    right: unset !important;
    @media screen and (max-width: 767px) {
      right: 0 !important;
    }
  }
}
.native-token-reward {
  .span-html {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 140%;
    color: white !important;
  }
}

@media screen and (max-width: 767px) {
  .token-input-wrapper {
    .left,
    .clickable {
      font-size: 12px !important;
    }
  }
}
.golden-border {
  border: 1px solid #ffa722 !important;
}
