.react-datepicker__input-container {
  width: fit-content;

  input {
    padding: 0;
    font-size: 14px;
    width: 100px;
    height: 20px;
    color: #87878b;
  }
}

.react-datepicker-wrapper {
  width: fit-content !important;
}

.react-datepicker {
  background-color: #29292c;
  position: relative;
  border: none;
  // box-shadow: rgba(52,245,174, 0.15) 0px 48px 100px 0px;
}

.react-datepicker__header {
  background: #29292c;
  width: 250px;
  height: 60px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 4px;
}

.react-datepicker__current-month {
  font-size: 16px;
  color: white;
  font-weight: 500 !important;
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
  font-size: 12px;

  .react-datepicker__day-name,
  .react-datepicker__day {
    color: white;
  }
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.react-datepicker__day {
  padding: 3px;
  width: 24px;
  height: 24px;
  margin: 0;

  &.react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #34f5ae !important;
  }
}

.react-datepicker__triangle {
  left: 45px !important;
  transform: unset !important;

  &::after {
    border-top-color: #29292c !important;
    border-bottom-color: #29292c !important;
  }

  &::before {
    border-bottom-color: #29292c !important;
    border-top-color: #29292c !important;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: #34f5ae !important;
}

// .react-datepicker__current-month
