.GlpSwap-content {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  // border-bottom-left-radius: 0.8rem;
  // border-bottom-right-radius: 0.8rem;
}

.Tab-title {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 3.41rem;
  font-weight: 500;
}

.Tab-description {
  letter-spacing: 0px;
  color: #a9a9b0;
  margin-top: 0.8rem;
}

.Tab-description span {
  color: #f89191;
}

.GlpSwap-from-token .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.GlpSwap-from-token .Modal .Modal-content {
  width: 41.85rem;
}

.GlpSwap-from-token .Modal-content {
  position: absolute;
  width: 38.75rem;
  max-height: 100vh;
  overflow: hidden;
  border: none;
  top: 0;
  bottom: 0;
}
.GlpSwap-from-token .Modal-content .Modal-body {
  // padding-right: 0.5rem;
  max-height: calc(100vh - 7.75rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
}

.GlpSwap-content .GlpSwap-stats-card,
.GlpSwap-content .GlpSwap-box {
  width: 100%;
  @media (max-width: 767px) {
    padding: 16px !important;
  }
}

.GlpSwap-content .GlpSwap-stats-card {
  // max-width: 71.2rem;
  // margin-right: 1.5rem;
}

.GlpSwap-stats-card .App-card-row .label {
  color: #a9a9b0;
  opacity: 1;
}

.GlpSwap-content .GlpSwap-box {
  max-width: 47.95rem;
  border-radius: 0.4rem;
  padding: 1.5rem;
  padding-top: 0;
  background: #191b2e;
  border-radius: 16px;
}

.GlpSwap-box .Exchange-swap-option-tabs {
  margin: 0 0 1.5rem;

  .active {
    color: #34f5ae;
  }

  .Tab-option {
    font-weight: 700 !important;
  }
}

.App-card-title-mark-icon {
  position: relative;
  margin-right: 0.8rem;
  display: flex;
}

.App-card-title-mark-icon .selected-network-symbol {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #1e1f37;
  border-radius: 50%;
}

.App-card-title-mark {
  display: flex;
}

.App-card-title-mark .App-card-title-mark-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
}

.App-card-title-mark .App-card-title-mark-subtitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0px;
  color: #a9a9b0;
}

.GlpSwap-token-list {
  background: #191b2e;
  border-radius: 16px;
  padding: 0 1.5rem 1.5rem;
  text-align: left;
}

.GlpSwap-token-list-content {
  display: grid;
  padding: 1.5rem;
  padding-top: 0;
  text-align: left;
}

.GlpSwap-box .App-card-title {
  margin-bottom: 0.8rem;
}

.GlpSwap-box .Exchange-info-row {
  height: 1.5rem;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GlpSwap-box .Exchange-info-row .Exchange-info-label {
  margin-right: 0.8rem;
  color: #a9a9b0;
  opacity: 1;
}

.GlpSwap-cta {
  margin: 0 auto;
  text-align: center;
  padding-top: 0;
}

.GlpSwap-cta .Exchange-swap-button {
  margin: 0 auto;
  justify-content: center;
  font-size: var(--font-sm);
  padding: 0;
}

.token-table {
  width: 100%;
  border-spacing: 0;
  white-space: nowrap;
  font-size: var(--font-base);

  .token-symbol-wrapper {
    display: flex;
    align-items: center;
  }

  .App-card-info-subtitle {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

.token-table td,
.token-table th {
  padding: 1.1625rem;
}

// .token-table td:nth-child(2),
// .token-table th:nth-child(2) {
//   width: 11rem;
// }

// .token-table td:nth-child(3),
// .token-table th:nth-child(3) {
//   width: 32rem;
// }

// .token-table td:nth-child(4),
// .token-table th:nth-child(4) {
//   width: 20rem;
// }

// .token-table td:nth-child(5),
// .token-table th:nth-child(5) {
//   width: 13rem;
// }

.token-table td {
  letter-spacing: 0.25px;
  color: #ffffffde;

  &.positive {
    color: #34f5ae;
  }
  &.negative {
    color: #f6475d;
  }
}

.token-table th:first-child,
.token-table td:first-child {
  padding-left: 1.5rem;
  width: 26.35rem;
}

.token-table th:last-child,
.token-table td:last-child {
  padding-right: 1.5rem;
  // text-align: right;
  width: 17rem;
}

.token-table th {
  font-weight: normal;
  letter-spacing: 0.25px;
  color: #a9a9b0;
  text-align: left;
}

.token-table .action-btn {
  width: 100%;
  justify-content: center;
}
.cap-reached {
  margin-left: 0.8rem;
}

.AppOrder-ball-container {
  position: relative;
  z-index: 2;
}

.AppOrder-ball-icon {
  text-align: center;
  display: block;
  font-size: var(--font-lg);
  opacity: 0.7;
}

.AppOrder-ball {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 3.565rem;
  height: 3.565rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -2.2rem;
  border-radius: 3.1rem;
  user-select: none;
  background: linear-gradient(90deg, rgba(45, 66, 252, 1) 0%, rgba(46, 61, 205, 1) 100%);
  cursor: pointer;
  opacity: 0.85;
}
.AppOrder-ball:hover {
  opacity: 1;
}

@media (max-width: 900px) {
  .GlpSwap-content {
    flex-wrap: wrap;
  }

  .GlpSwap-content .GlpSwap-stats-card {
    margin: 0;
    margin-bottom: 3.1rem;
    max-width: 100%;
  }

  .GlpSwap-content .GlpSwap-box {
    margin: 0;
    max-width: 100%;
  }
}

@media (max-width: 1100px) {
  .GlpSwap-from-token .Modal {
    align-items: center;
    justify-content: center;
  }
  .GlpSwap-from-token .Modal .Modal-content {
    width: 41.85rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }
  .GlpSwap-from-token .Modal .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(80vh - 8.525rem);
  }

  .GlpSwap-token-list {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .GlpSwap-token-list-content {
    padding: 0;
  }

  // .token-grid {
  //   display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  //   grid-gap: 1.5rem;
  //   margin-top: 0.8rem;
  // }
}
// @media (max-width: 510px) {
//   .token-grid {
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-gap: 1.5rem;
//     margin-top: 0.8rem;
//   }
// }

.GlpSwap-stats-card {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 24px;
  background-color: transparent !important;
  position: relative;
  padding: 0 !important;
  padding-top: 60px !important;

  .App-card-title {
    position: absolute;
    top: 0;

    .App-card-title-mark-icon {
      max-height: 36px;
    }

    .App-card-title-mark-info {
      display: flex;
      align-items: center;
      gap: 4px;

      .value {
        color: #34f5ae;
      }
    }
  }

  .App-card-content {
    padding: 24px;
    border-radius: 16px;
    background-image: linear-gradient(to left, #002e58cc, #191b2ecc);
    display: flex;

    > div {
      flex: 1;
    }

    .App-card-row {
      display: flex;
      flex-direction: column;
      gap: 4px;

      > * {
        text-align: left;
      }

      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
      }

      .value {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #ffffff99;
      }
    }

    @media (max-width: 767px) {
      gap: 16px;
    }
  }
}

.GlpSwap-box {
  width: 100%;
  max-width: 424px !important;
  // margin-top: 60px;
  padding: 24px !important;

  .Exchange-swap-option-tabs {
    margin-bottom: 20px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    background-color: #191b2e;
  }

  .Exchange-swap-section {
    margin-bottom: 0 !important;
  }
  .Exchange-swap-section.buy-input {
    min-height: 80px;
    background-color: #11121b;
    position: relative;

    @media (max-width: 767px) {
      .Exchange-swap-section-bottom {
        position: absolute;
        bottom: 4px;
        left: 12px;
        right: 12px;
      }
    }
  }
  .Exchange-swap-input,
  .selected-token {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    img {
      margin-right: 4px;
    }
  }
  .buy-input .PositionEditor-token-symbol img {
    display: block !important;
  }
  .AppOrder-ball-container {
    height: 16px;
  }
  .AppOrder-ball {
    width: 36px;
    height: 36px;
    top: 50% !important;
    transform: translateY(-50%);
  }
  .Exchange-info-row {
    margin: 8px 0 22px;
  }
  .Exchange-swap-button {
    height: 40px !important;
  }

  .Exchange-swap-section-top,
  .Exchange-swap-section-bottom {
    > * {
      font-weight: 400 !important;
    }

    .Exchange-swap-label {
      font-weight: 400 !important;
    }

    .Exchange-swap-balance {
      color: #ffffff99;
    }

    .PositionEditor-token-symbol {
      margin-left: 4px;
    }

    .TokenSelector-box-symbol {
      margin-left: 0;
    }
  }

  @media (max-width: 1023px) {
    max-width: unset !important;
  }
}

.GlpSwap {
  .token-table {
    .Tooltip-popup {
      width: fit-content !important;
    }
  }

  .buy-tooltip {
    a {
      color: #fff;
      &:hover {
        color: #03f5ae !important;

        span {
          color: #03f5ae !important;
        }
      }
    }
  }

  .Page-title {
    margin-bottom: 12px;
  }

  .Tooltip-row-values {
    @media (max-width: 767px) {
      > li {
        text-align: left !important;
      }
    }
  }

  .Tab-title-section {
    @media (max-width: 767px) {
      padding-top: 64px;
    }
  }

  // @media (max-width: 767px) {
  //   .GlpSwap-content {
  //     align-items: stretch;
  //   }
  // }

  .Tab-title-section {
    .Page-title {
      span {
        font-size: 24px;
      }
    }
  }

  &.theme--light {
    .buy-tooltip {
      a {
        &:hover {
          color: #02b27f !important;

          span {
            color: #02b27f !important;
          }
        }
      }
    }
  }
}

.markets-content {
  table.markets-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;

    th {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 140% !important;
      color: #ffffffb2 !important;
      text-align: left;
      padding-left: 16px;
    }

    tbody {
      tr {
        padding-top: 8px;
        > td {
          padding: 8px 16px;
          background-color: #191b2e;

          &:first-child {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }

          &:last-child {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }
      .token > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        img {
          width: 36px;
          height: 36px;
        }

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
        }
      }

      .pool {
      }

      .dailyChange {
      }

      .dailyHigh {
      }

      .dailyVolume {
      }

      .openInterest {
      }

      .chart {
      }
    }
  }

  &.mobile {
    background-color: #191b2e;
    padding: 16px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 12px;
    border-radius: 12px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
