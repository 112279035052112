.nft-select-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .available {
    color: rgba(255, 255, 255, 0.6);

    span {
      color: white;
      font-weight: 700;
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 12px;

    @media screen and (max-width: 400px) {
      gap: 8px;
    }

    .selected {
      color: rgba(255, 255, 255, 0.6);

      span {
        color: white;
        font-weight: 700;
      }
    }

    .select-btn {
      cursor: pointer;
      color: #03f5ae;
    }
  }
}
.nft-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  gap: 12px;
  max-height: 240px;
  overflow-y: auto;
  padding-right: 10px;

  // &::-webkit-scrollbar {
  //   width: 8px !important;
  //   background: #191b2e !important;
  //   border-radius: 100px !important;
  // }

  // &::-webkit-scrollbar-track {
  //   width: 8px !important;
  //   background: #191b2e !important;
  //   border-radius: 100px !important;
  // }

  // &::-webkit-scrollbar-thumb {
  //   width: 8px !important;
  //   background: #4d527c;
  //   border-radius: 100px !important;
  // }

  @media screen and (max-width: 700px) {
    padding-right: 8px;
  }

  .list-item-selected {
    border: 1px solid #03f5ae;
  }

  .list-item {
    width: calc(50% - 6px);
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    background: #191b2e;
    border-radius: 12px;
    cursor: pointer;

    @media screen and (max-width: 700px) {
      width: 100% !important;
    }

    .nft-info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }

      .info {
        opacity: 0.6;

        span {
          font-size: 12px;
          line-height: 140%;
        }
      }
    }
  }
}
.actions {
  margin-top: 24px;
  width: 100%;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin: 0;
  }
}
.nft-selected-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .nft-selected-item {
    position: relative;

    .main-nft {
      border-radius: 12px;
      width: 64px;
      height: 64px;
      position: relative;
      z-index: 1;
    }

    .ic-close {
      position: absolute;

      top: -2px;
      right: -2px;
      z-index: 3;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .overlay {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 12px;
    }
  }
}
.modals-container {
  .StakeModal {
    .Modal-content {
      max-width: 592px;
      width: 90%;
    }
  }
}
.txt-muted {
  color: rgba(255, 255, 255, 0.6);
}

.modal-mint {
  .menu-items {
    background: #191b2e;
    .menu-item {
      padding: 8px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: #fff;
      &:hover {
        background: #20233b !important;
      }
    }
  }

  .Modal-content .Modal-body {
    padding: 0 24px 24px 24px !important;
    overflow-x: hidden;
  }

  &.theme--light {
    .last-mint-label {
      background: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      color: #ffffff !important;
    }
  }
}
.red-warning {
  margin-top: 8px;
  color: #f6475d;
}

//corfirm modal
.modal-confirm-mint {
  z-index: 10000;
  .Modal-content {
    width: 400px;
  }
}
