.token-migration-container {
  display: flex;
  align-items: center;
  gap: 24px;

  .tab-token {
    flex: 1;
  }

  .tab-lp {
    .tab-token-detail {
      width: 312px;
    }
  }

  .tab-side {
    display: flex;
    align-items: center;
    gap: 24px;

    .tab-token-detail {
      flex: 1;
      border-radius: 16px;
      background: var(--Nature-1, #191B2E);
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      .rate {
        margin-top: 12px;
        ;
      }

      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%
      }

      .desc {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: rgba(255, 255, 255, 0.60);
      }

      .rates-container {
        display: flex;
        width: 100%;
        gap: 12px;

        .rate-content {
          display: flex;
          flex-direction: column;
          gap: 12px;
          flex: 1;

          .rate-info {
            border-radius: 12px;
            background: var(--Nature-2, #20233B);
            display: flex;
            padding: 12px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
          }

          .arrow {
            display: flex;
            align-items: center;
            width: 100%;

            .divider {
              margin: 0 !important;
              flex: 1;
            }
          }

          .info {
            display: flex;
            gap: 4px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            display: flex;
            align-items: center;

            img {
              margin-right: 4px;
              width: 24px;
              height: 24px;
            }
          }
        }

      }
    }
  }
}
