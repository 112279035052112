/* .menu-items:focus-visible {
  border: 1px solid #262638;
} */
.address-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.user-address {
  /* margin-left: 1rem; */
  margin-right: 1rem;
  font-weight: 400;
}

.App-header-user-address:hover {
  background: #808aff14;
}

.menu-items {
  position: absolute;
  right: 0;
  top: 4.3rem;
  min-width: 258px;
  width: 100%;
  padding: 8px;
  transform-origin: top right;
  border-radius: 0.4rem;
  background: #16182e;
  /* border: 1px solid #20233B; */
  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
  background: #20233b;
  border-radius: 12px;
  border: none;

  overflow: hidden;
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 12px 16px;
  border-radius: var(--base-unit-s-12, 12px);
  opacity: 1;
}
.menu-item:hover {
  background: #191b2e !important;
  opacity: 1;
  color: #eee !important;
}
.menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 370px) {
  .user-address {
    margin-left: 0;
  }
  .address-btn {
    display: flex;
    justify-content: space-between;
  }
  .user-avatar {
    display: none;
  }
}
