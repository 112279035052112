.Exchange-wave {
  z-index: 1;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 1.5rem;
  bottom: 1.5rem;
  mask-image: url(img/wave-01.png);
  mask-size: 90% 90%;
  mask-repeat: no-repeat;
  mask-position: center;
  filter: blur(5rem);
  background: linear-gradient(40deg,
      rgba(96, 4, 189, 1) 0%,
      rgba(65, 105, 224, 1) 22%,
      rgba(99, 8, 191, 1) 23%,
      rgba(51, 9, 115, 1) 37%,
      rgba(255, 36, 145, 1) 38%,
      rgba(201, 16, 161, 1) 50%,
      rgba(171, 10, 163, 1) 59%,
      rgba(117, 4, 128, 1) 67%,
      rgba(96, 29, 196, 1) 68%,
      rgba(92, 55, 204, 1) 84%,
      rgba(84, 88, 214, 1) 100%);
  pointer-events: none;
}
