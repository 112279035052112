.Bootstrap-Modal.theme--light {
  .box {
    background: #fff;

    .box-title {
      color: rgba(13, 26, 22, 0.7);
    }

    .box-value {
      color: var(--Text-Text_Primary, #0d1a16);

      &.green {
        color: #02b27f;
      }
    }

    .box-inUsd {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
    }
  }

  .text {
    color: #0d1a16;
  }

  .warning {
    color: var(--functional-orange, #ffa722);
  }

  .content-box-row {
    .content-box-row-label,
    .content-box-row-value span {
      color: rgba(13, 26, 22, 0.7);
    }
  }
}
