.earn-content-container {
  margin-top: 64px;
  flex: 1;

  .tab-content-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }

  .desc {
    color: var(--white-60, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    margin-top: 16px;
  }

  .tab-content-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 8px;
  }

  .tab-main-content {
    margin-top: 24px;
    display: flex;
    gap: 24px;

    .main-content {
      flex: 1;

      > .heading {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        /* 22.4px */
      }
    }

    .overview-container {
      background: radial-gradient(50% 285.34% at 100% 0%, rgba(0, 46, 88, 0.8) 0%, rgba(25, 27, 46, 0.8) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      border-radius: 16px;
      padding: 24px;

      & + .overview-container {
        margin-top: 24px;
      }

      .main-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;

        &.custom {
          & > span {
            color: var(--main-primary, #03f5ae);
            font-size: 16px;
            font-weight: 700;
            line-height: 140%;
            /* 22.4px */

            &.highlight {
              color: var(--Warning, #ffdf76);
            }
          }
        }
      }

      .rewards-wrap {
        display: flex;
        align-items: stretch;
        gap: 24px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          gap: 16px;

          .overview-content-container {
            border: none;
            padding: 0;
            margin: 0;
          }
        }
      }

      .total-reward {
        display: flex;
        gap: 8px;
        margin-top: 24px;

        .reward-img {
          background: linear-gradient(138.67deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%);
          border-radius: 16px;
          padding: 12px;
        }

        .reward-info {
          .reward-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: rgba(255, 255, 255, 0.6);
          }

          .reward-value {
            font-weight: 700;
            font-size: 24px;
            line-height: 140%;
            color: #03f5ae;
            margin-top: 4px;
          }
        }

        @media screen and (max-width: 767px) {
          margin-top: 16px;
        }
      }

      .overview-content-container {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        padding-left: 24px;
        border-left: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
        flex: 1;

        .overview-content {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;

          &:first-child {
            margin-top: 0;
          }

          .title {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: rgba(255, 255, 255, 0.6);
          }

          .value {
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;

            span {
              font-weight: 400;
              color: rgba(255, 255, 255, 0.6);
            }
          }

          @media screen and (max-width: 767px) {
            margin-top: 12px;
          }
        }
      }

      .actions-contaner {
        margin-top: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;

        &.no-account {
          grid-template-columns: 1fr;
        }

        button {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          margin: 0;
          font-weight: 700;
        }

        @media screen and (max-width: 767px) {
          margin-top: 16px;
        }
      }
    }

    .right-content {
      padding: 0 !important;
      background: none !important;
      .left-box-content {
        border-bottom: none !important;
      }
      .right-content-box {
        margin-top: 16px;

        &:first-child {
          margin-top: 0;
        }

        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 16px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .box-info {
          width: calc(50% - 38px);

          .title {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: rgba(255, 255, 255, 0.6);

            &.custom {
              display: flex;
              align-items: flex-start;
              gap: var(--base-unit-xs-8, 8px);

              > span {
                display: flex;
                padding: 2px var(--base-unit-xs-8, 8px);
                justify-content: center;
                align-items: center;
                gap: var(--base-unit-xs-8, 8px);
                border-radius: var(--Border_radius, 4px);
                background: var(--Warning, #ffdf76);
                color: var(--black-100, #000);
                font-size: 12px;
                font-weight: 700;
                line-height: 140%;
                /* 16.8px */
              }
            }
          }

          .value {
            margin-top: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;

            @media screen and (max-width: 768px) {
              .tooltip-apr {
                .Tooltip-popup {
                  left: -150px !important;
                }
              }
            }

            .Tooltip {
              margin-left: 0 !important;

              .Tooltip-handle {
                margin-left: 0 !important;
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                color: white;
              }
            }

            .sub-value {
              font-weight: 400;
              color: rgba(255, 255, 255, 0.6);
              margin-left: 4px;
              font-size: 14px;
              margin-left: 4px;
            }
          }
        }

        @media screen and (max-width: 767px) {
          .box-info:nth-child(2) {
            text-align: right;
          }

          > a {
            width: fit-content;
            margin: 16px 0 0 auto;
          }
        }

        @media screen and (max-width: 700px) {
          flex-wrap: wrap;

          .box-info {
            width: 50%;
          }

          // .border-btn {
          //   width: 100% !important;
          //   margin: 16px 0 0 0 !important;
          //   justify-content: center;
          // }
        }
      }

      .right-content-border-box {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 16px;
        padding: 24px;

        .box-main-price {
          background: radial-gradient(50% 285.34% at 100% 0%, rgba(0, 46, 88, 0.8) 0%, rgba(25, 27, 46, 0.8) 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          border-radius: 16px;
          padding: 16px;
          position: relative;

          .price-info {
            .title {
              color: rgba(255, 255, 255, 0.6);
              font-weight: 400;
              font-size: 12px;
              line-height: 140%;
            }

            .value {
              font-weight: 700;
              font-size: 16px;
              line-height: 140%;
            }
          }

          img {
            position: absolute;
            right: 8px;
            top: 0;
            height: 98%;
          }
        }

        .buy-btn-container {
          margin-top: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;

          a {
            flex: 1;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
          }
        }

        .box-devider {
          display: flex;
          align-items: center;
          margin-top: 24px;

          .divider-title {
            color: rgba(255, 255, 255, 0.6);
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
          }

          .divider-line {
            flex: 1;
            height: 1px;
            background: rgba(255, 255, 255, 0.1);
          }
        }

        .box-apr {
          font-weight: 700;
          font-size: 16px;
          line-height: 140%;
          color: #03f5ae;
          margin-top: 8px;
        }

        .box-token-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;

          .title {
            color: rgba(255, 255, 255, 0.6);
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: rgba(255, 255, 255, 0.6);
          }

          .value {
            display: flex;
            align-items: center;
            gap: var(--Border_radius, 4px);
            color: var(--White-100, #fff);
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 19.6px */

            & > span {
              color: var(--White-60, rgba(255, 255, 255, 0.6));
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }

            &:has(.Tooltip-handle) {
              .Tooltip-handle {
                display: flex;
                align-items: center;
                gap: var(--Border_radius, 4px);
                color: var(--White-100, #fff);
                font-size: 14px;
                font-weight: 700;
                line-height: 140%; /* 19.6px */

                & > span {
                  color: var(--White-60, rgba(255, 255, 255, 0.6));
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 140%; /* 19.6px */
                }
              }
            }
          }
        }
      }
    }

    .left-content {
      .left-box-content {
        background: #191b2e;
        border-radius: 16px;
        padding: 24px;
        margin-top: 24px;

        &:first-child {
          margin-top: 0;
        }

        .main-title-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 8px;
          .pending-rewards-value {
            font-size: 24px;
            font-weight: 700;
            line-height: 140%;
            color: #03f5ae;
          }
        }

        .main-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 140%;
        }

        .text-button-green {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          a {
            color: #03f5ae;
            cursor: pointer;
            text-decoration: none;
          }
        }
        .pending-rewards-content {
          margin-top: 16px !important;
        }
        .rewards-container {
          display: flex;
          margin-top: 16px;

          .reward-info {
            width: 50%;

            .title {
              color: rgba(255, 255, 255, 0.6);
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
            }

            .value {
              font-weight: 500;
              font-size: 16px;
              line-height: 140%;
              margin-top: 8px;

              span {
                font-weight: 400;
                color: rgba(255, 255, 255, 0.6);
                font-size: 14px;
              }
            }
          }
        }

        .box-desc {
          color: rgba(255, 255, 255, 0.6);
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          margin-top: 8px;
        }

        .rewards-es-container {
          display: flex;
          margin-top: 16px;

          .account-info {
            flex: 1;
            padding-right: 16px;

            .reward-info {
              margin-top: 16px;

              &:first-child {
                margin-top: 0;
              }

              .title {
                color: rgba(255, 255, 255, 0.6);
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                margin-bottom: 8px;
              }

              .value {
                font-weight: 700;
                font-size: 14px;
                line-height: 140%;
                margin-top: 8px;

                span {
                  font-weight: 400;
                  font-size: 14px;
                  color: rgba(255, 255, 255, 0.6);
                }
              }
            }
          }

          .button-actions {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 12px;

            button {
              width: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              margin: 0 !important;
              font-weight: 700;

              @media screen and (max-width: 767px) {
                width: unset;
              }
            }
          }
        }
        .mlp-claim-reward {
          width: 100%;
          margin-top: 16px !important;
        }
      }
    }
  }

  @media screen and (max-width: 1033px) {
    margin-top: 64px;

    .tab-main-content {
      flex-direction: column;
      gap: 24px;

      &.overview {
        flex-direction: column-reverse;
      }

      .main-content {
        flex: none;
        width: 100%;
      }
    }

    // padding-bottom: 120px;
  }

  @media screen and (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;

    // padding-bottom: 280px;
    .left-box-content {
      padding: 16px !important;

      &:not(:first-child) {
        margin-top: 16px !important;
      }
    }

    .stake-box-content {
      margin-top: 16px !important;
    }

    .right-content-border-box {
      padding: 16px !important;
    }

    .rewards-es-container {
      flex-direction: column;
      gap: 16px;

      .button-actions {
        display: flex;
        justify-content: space-between;
        flex-direction: row !important;
      }
    }

    .overview-container {
      padding: 16px !important;

      .reward-img {
        height: fit-content;

        img {
          width: 24px;
        }
      }

      // .reward-info {
      //   .reward-title {
      //     font-weight: 400;
      //     font-size: 12px !important;
      //     line-height: 140%;
      //   }

      //   .reward-value {
      //     font-weight: 700;
      //     font-size: 16px !important;
      //     line-height: 140%;
      //   }
      // }
    }
  }
}

.box-apr,
.box-info {
  .Tooltip-popup {
    right: unset !important;
    left: 0 !important;
  }
}

.native-token-reward {
  .span-html {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 140%;
    color: white !important;
  }
}

.green-pending {
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 140% !important;
  margin-top: 8px;
}
