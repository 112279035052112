.VestPage.theme--light {
  & > .title {
    color: var(--Text-Text_Primary, #0d1a16);
  }

  & > .description {
    color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

    .link-underline {
      color: #0d1a16b3;

      &:hover {
        color: #02b27f;
      }
    }
  }

  & > .content {
    & > div {
      background-image: linear-gradient(138.67deg, #fff 8.79%, #fff 100%),
        linear-gradient(138.67deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%) !important;

      .title {
        color: var(--Text-Text_Primary, #0d1a16) !important;
      }

      .claim-area {
        border-radius: var(--Base-Unit-XS-8, 8px) !important;
        background: #f2f5f7 !important;

        .top-left {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }

        .top-right a {
          color: var(--Primary, #02b27f) !important;

          &:hover {
            color: #03805b !important;
          }
        }
      }

      .info {
        .child {
          .title {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
          }

          .value {
            color: var(--Text-Text_Primary, #0d1a16) !important;
          }
        }
      }
    }
  }
}
